import { mapGetters } from "vuex";
import header from "@/assets/table/stock/stock.js";
import ListContextMenu from "@/components/atoms/ContextMenu/ListContextMenu.vue";
import filterNormal from "@/assets/image/svg/filter.svg";
import filterActive from "@/assets/image/svg/filter_active.svg";
import DatePicker from "@/components/atoms/Vdate/DatePicker.vue";
import menuTab from "@/components/atoms/Tabs/menuTab.vue";
import NewStock from "./NewStock";
import GreigeStock from "@/components/pages/stock/GreigeStock.vue";
import commitName from "@/components/pages/common/ApiActionName.js";
import { stockFilters } from "@/api/stock";

import settingFormDialog from "@/components/organisms/VDialog/SettingFormDialog";
import DenseSubItemComboBox from "@/components/atoms/VComboBox/DenseSubItemComboBox.vue";
export default {
  components: {
    filterNormal,
    filterActive,
    ListContextMenu,
    NewStock,
    GreigeStock,
    DatePicker,
    settingFormDialog,
    DenseSubItemComboBox,
    menuTab,
  },
  computed: {
    ...mapGetters({
      pageParams: "getStockParam",
      pageList: "getStockTableList",
      totalCount: "getStockTotalCount",
      monthList: "getMonthList",
      sort: "getstockSort",
      orderList: "getOrderList",
      editStockForm: "getEditStockForm",
      stockTotal: "getStockTotal",
      allCheckBox: "getStockCheckbox",
      pageIndex: "getPageIndex",
      getStockType: "getStockSelected",
    }),

    searchData() {
      return this.setSearchData("search", this.names.searchCommitName);
    },
    totalData() {
      return this.setSearchData("search", this.names.totalCommitName);
    },
    infiniteScrollData() {
      return this.setSearchData("infinite", this.names.infiniteCommitName);
    },
    isPluseParamBtn() {
      const paramIndex = this.pageParams.findIndex(
        (param) => param.field === this.filterField,
      );
      return !!this.isKeyword && -1 < paramIndex;
    },
    isApplyBtn() {
      let isApply = this.isKeyword
        ? this.isPluseParam
          ? !this.isPluseParam
          : !this.keywordFilters.filter((x) => x.checked).length
        : !this.filters.filter((x) => x.checked).length;
      return isApply;
    },
  },
  data() {
    return {
      header,
      observer: null,
      names: commitName["stock"],

      filterField: "", // 현재 선택한 filter field
      isFilter: false,
      allChecked: false, // 모두 선택 checkbox
      keywordAllChecked: false, // keywod 검색 filter 모두 선택 check box
      filters: [], // 현재 filter 데이터
      params: [], // checked 가 true 데이터
      infinitieSearchField: "", //무한 스크롤 field
      // sort: 1, // 정렬 선택 1: 오림차순, -1 :내림 차순
      isKeyword: false, // filter keyword 입력 여부
      findKeyword: "", // filter 검색을 위한 keyword
      keywordFilters: [], // keyword 가 포함된 filter
      isPluseParam: false,

      onRow: "",

      stockSelected: "stock",
      menu_date_register: false,
      stockType: [
        { title: "재고", value: "stock" },
        { title: "마감", value: "end" },
        { title: "전체", value: "common" },
      ],
      stockKind: [
        { title: "일반", value: 3 },
        { title: "제직", value: 4 },
      ],
      keydownListener: null,
      listTypes: [
        {
          value: "fabric_stock",
          label: "원단 재고",
        },
        {
          value: "raw_stock",
          label: "생지 재고",
        },
        {
          value: "manu_stock",
          label: "가공 재고",
        },
      ],
    };
  },

  methods: {
    /**
     * api 호출
     */
    // 검색 할 값 생성
    setSearchData(type, name) {
      if (!this.pageParams.length) this.infinitieSearchField = "";
      const data = {
        param: {
          multi: JSON.stringify(this.pageParams),
          field:
            type === "infinite"
              ? this.infinitieSearchField
              : this.setFilterField(),
          sort: this.sort,
          type: this.getStockType,
          pageType: "fabric",
        },
        commitName: name,
        isInfinite: type !== "infinite",
      };
      if (type === "infinite") data.param.skip = this.pageList.length;

      return data;
    },

    // 검색 api 호출
    search() {
      this.callTotalCountAPI();
      this.callSearchAPI();
    },

    // 검색시 sort field 생성
    setFilterField() {
      let field = "";
      if (this.filterField) {
        field = this.filterField;
      }
      if (this.pageParams.length && !this.filterField) {
        field = this.pageParams[this.pageParams.length - 1].field;
      }
      return field;
    },

    // 내부 필터 데이터 호출 api
    async setFilters() {
      const head = header.find((haed) => haed.value === this.filterField);
      try {
        const result = await stockFilters({
          company: this.$store.state.auth_company,
          params: {
            field: this.filterField,
            multi: this.setFilterParam(),
            kind: this.names.kind,
            type: this.getStockType,
            pageType: "fabric",
            sort: head.sort,
          },
        });

        if (result.status !== 200) throw `error: ${result.status}`;

        const params = this.copyData(
          this.pageParams.find((x) => x.field === this.filterField),
        );

        if (!params) {
          this.filters = result.data.length ? result.data : [];
          this.setParams();
        } else {
          this.params = params.params;
          this.filters = result.data.map((x) => {
            const param = this.params.find((name) => name === x.name);
            return {
              name: x.name,
              checked: param !== undefined,
            };
          });
          this.allChecked = this.params.length === this.filters.length;
        }
      } catch (error) {
        console.log(error);
      }
    },

    // filter api 호출시 현재 필드 값을 뺴고 multi 생성
    setFilterParam() {
      let params = [];
      const filterIndex = this.pageParams.findIndex(
        (x) => x.field === this.filterField,
      );
      if (-1 < filterIndex) {
        for (let i = 0; i < filterIndex; i++) {
          params.push(this.pageParams[i]);
        }
      } else params = this.pageParams;

      return JSON.stringify(params);
    },

    // 오더 data 검색 api
    async callSearchAPI() {
      this.$store.dispatch(this.names.searchApiName, this.searchData);
      this.infinitieSearchField = this.setFilterField();
    },

    //무한스크롤 API
    async infiniteScroll() {
      this.$store.dispatch(this.names.searchApiName, this.infiniteScrollData);
    },

    //검색된 오더 total count api
    async callTotalCountAPI() {
      this.$store.dispatch(this.names.totalApiName, this.totalData);
    },

    //head 에 값에 따로 sort 값 전달
    headSort(field) {
      const head = header.find((haed) => haed.value === field);
      return head && head.sort ? head.sort : 1;
    },

    /**
     * 필터 이벤트
     */

    // 필터 비콘 클릭 이벤트
    filterBeaconClick(head) {
      this.filterField !== head ? this.filterSet(head) : this.initFilter();
      this.findKeyword = "";
      this.params = [];
    },

    //필터 초기화 기능
    initFilter() {
      this.filterField = "";
      this.findKeyword = "";
      this.isFilter = false;
      this.allChecked = false;
      this.isKeyword = false;
      this.filters = [];
      this.keywordFilters = [];
    },

    // 필터 set 기능
    filterSet(head) {
      this.filterField = head;
      this.isFilter = true;
      this.filters = [];
      this.setFilters();
    },

    // 필터 활성화시 최초 params set
    setParams() {
      this.params = this.filters.filter((x) => x.checked).map((x) => x.name);
      this.allChecked = this.params.length === this.filters.length;
    },

    allChekedClick() {
      this.allChecked = !this.allChecked;
      this.filters = this.filters.map((x) => {
        x.checked = this.allChecked;
        return x;
      });
      this.params = this.allChecked
        ? this.filters.filter((x) => x.checked).map((x) => x.name)
        : [];
    },

    // checkbox 클릭시 이벤트
    checkBoxClick(i) {
      this.filterChecked(i);
      this.modifyParams(i);
      this.allChecked = this.params.length === this.filters.length;
    },

    // filter checked 변경
    filterChecked(i) {
      const filters = this.copyData(this.filters);
      filters[i].checked = !filters[i].checked;
      this.filters = filters;
    },

    // 현재 활성화된 filter 검색 params set
    modifyParams(i) {
      const filter = this.filters[i];
      if (filter.checked) {
        this.params.push(filter.name);
        this.params = [...new Set(this.params)];
      } else {
        const index = this.params.findIndex((x) => x === filter.name);
        this.params.splice(index, 1);
      }
    },
    // 필터 적용
    applyBtnClick() {
      this.$store.commit(
        this.names.sortCommitName,
        this.headSort(this.filterField),
      );
      // this.sort = this.headSort(this.filterField);
      // this.sort = 1;
      this.commitParams();
      this.search();
      // this.modifyBeacon(true);
      this.initHeader();
      this.initFilter();
    },

    // 오름, 내림 차순
    sortBtnClick(sort) {
      // this.sort = sort;
      this.$store.commit(this.names.sortCommitName, sort);
      this.search();
      this.initFilter();
    },

    // beacon checked 변경
    modifyBeacon(checked) {
      const index = header.findIndex((head) => head.value === this.filterField);
      header[index].checked = checked;
    },

    // 검색 버튼 클릭시 멀티 params 값 변경
    commitParams() {
      const pageParams = this.copyData(this.pageParams);
      const paramIndex = this.pageParams.findIndex(
        (param) => param.field === this.filterField,
      );

      const params = {
        field: this.filterField,
        params: this.params,
      };
      if (paramIndex < 0) pageParams.push(params);
      else pageParams[paramIndex] = params;

      this.$store.commit(this.names.paramName, pageParams);
    },

    monthFilter(month, type) {
      const list = this.filters.filter(
        (date) => this.monthParse(date.name, type) === month,
      );

      if (list.length) {
        this.params = list.map((date) => date.name);
        this.applyBtnClick();
      } else this.initParams();
    },

    monthParse(date, type) {
      let parseDate = date ? date : "";

      if (type === "mmdd") parseDate = parseDate.substr(0, 2);

      if (type === "yymm") parseDate = parseDate.substr(3, 5);

      if (type === "yyyymm") parseDate = parseDate.substring(5, 7);

      if (type === "m" && +date < 10) parseDate = `0${parseDate}`;

      return parseDate;
    },

    // 필터 해제
    initParams() {
      this.params = [];
      const orderParams = this.copyData(this.pageParams);
      const index = orderParams.findIndex(
        (param) => param.field === this.filterField,
      );
      orderParams.splice(index, 1);
      this.$store.commit(this.names.paramName, orderParams);
      this.setFilters();
      this.initFilter();
      this.$store.commit(
        this.names.sortCommitName,
        this.headSort(this.setFilterField()),
      );
      this.search();
      this.initHeader();
    },
    /**
     * 필터 검색
     */
    // 입력된 keyword 를 filter List 검색
    inputKeyowrd() {
      this.isPluseParam = false;
      this.isKeyword = !!this.findKeyword;
      this.keywordFilters = this.copyData(
        this.filters.filter((x) =>
          String(x.name).toLowerCase().includes(this.findKeyword.toLowerCase()),
        ),
      ).map((x) => {
        x.checked = true;
        return x;
      });

      this.keywordAllChecked = this.keywordFilters.length
        ? !this.keywordFilters.find((x) => !x.checked)
        : false;
    },

    // 입력된 keyword filter checkbox 클릭
    keywordCheckBoxClick(i) {
      this.keywordFilters[i].checked = !this.keywordFilters[i].checked;
      const index = this.filters.findIndex(
        (x) => x.name === this.keywordFilters[i].name,
      );
      this.keywordAllChecked = !this.keywordFilters.filter((x) => !x.checked)
        .length;

      this.checkBoxClick(index);
    },

    // 입력된 keyword filter 전체버튼 클릭
    keywordAllcheckedClick() {
      const params = this.params;
      this.keywordAllChecked = !this.keywordAllChecked;
      this.keywordFilters = this.keywordFilters.map((x) => {
        x.checked = this.keywordAllChecked;
        return x;
      });
      this.keywordFilters.forEach((filter) => {
        if (this.keywordAllChecked) {
          params.push(filter.name);
        } else {
          const index = this.params.findIndex((x) => x === filter.name);
          params.splice(index, 1);
        }
      });
      this.params = [...new Set(params)];
    },

    keywordApplyBtnClick() {
      const keywordParam = this.keywordFilters
        .filter((x) => x.checked)
        .map((x) => x.name);

      if (!this.isPluseParam)
        this.params = keywordParam.length ? keywordParam : this.params;

      if (this.isPluseParam) {
        const originParam = this.filters
          .filter((x) => x.checked)
          .map((x) => x.name);

        this.params = [...new Set([...originParam, ...keywordParam])];
      }

      this.applyBtnClick();
    },

    // 숫자 필터 사용시 검색 함수
    numFiltering(field, pageParams) {
      this.filterField = field;
      this.$store.commit(this.names.paramName, pageParams);
      this.search();
      this.initHeader();
      this.initFilter();
    },

    // 숫자 필터 다이어로그 open
    numDialogOpen(type) {
      this.$store.dispatch("NUM_DIALOG_OPEN", {
        field: this.filterField,
        filters: this.copyData(this.filters),
        params: this.copyData(this.pageParams),
        type: type,
        numFiltering: this.numFiltering,
      });
    },

    async checkScroll() {
      const scroll = this.$refs.endPoint;
      this.observer = new IntersectionObserver(
        async ([entry]) => {
          if (entry.isIntersecting && this.pageList.length < this.totalCount) {
            this.infiniteScroll();
          }
        },
        {
          threshold: 0.5,
        },
      );
      this.observer.observe(scroll);
    },

    initHeader() {
      header.map((head) => {
        head.checked = !!this.pageParams.find(
          (param) => param.field === head.value,
        );
        return head;
      });
    },

    pageMove() {
      this.pageParams.splice(0, 1);
      this.$store.commit(this.names.paramName, this.pageParams);
      this.callTotalCountAPI();

      if (this.totalCount) {
        this.callSearchAPI();
        this.initHeader();
      } else {
        this.$store.commit(this.names.paramName, []);
        this.search();
      }
    },

    async changeStockType() {
      this.$store.commit(this.names.paramName, []);
      this.search();
      this.initHeader();
    },

    checkBoxAllChange() {
      this.$store.commit(this.names.checkboxCommitName, !this.allCheckBox);

      this.pageList.forEach((x) => {
        x.checkBox = this.allCheckBox;
      });
      this.setCheckBoxLength();
    },

    checkboxChange(item) {
      item.checkBox = !item.checkBox;
      if (!item.checkBox)
        this.$store.commit(this.names.checkboxCommitName, false);
      else {
        let count = 0;
        this.pageList.forEach((x) => {
          if (x.checkBox) count++;
        });
        if (count === this.pageList.length)
          this.$store.commit(this.names.checkboxCommitName, true);
      }
      this.setCheckBoxLength();
    },

    setCheckBoxLength() {
      this.$store.dispatch("SET_CHECKBOXLENGTH", this.pageList);
    },

    yardExQuantity(item) {
      return (+item * 1.094).toFixed(0);
    },
    saveValidate(data) {
      if (!data.item) {
        this.$store.commit("setSnackBar", `아이템을 선택해주세요.`);
        return false;
      }
      if (!data.stock_quantity) {
        this.$store.commit("setSnackBar", `재고수량을 입력해주세요.`);
        return false;
      }
      return true;
    },
    editStock(item) {
      if (!this.saveValidate(this.editStockForm)) {
        return false;
      }

      this.$store.dispatch("TASK_SAVE_STOCK", {
        data: this.copyData(this.editStockForm),
        type: "edit",
      });
      this.onRow = "";
    },
    async clickRow(index, item) {
      this.onRow = index;

      const editItem = JSON.parse(JSON.stringify(item));
      editItem.item = this.$store.state.itemList.find(
        (x) => x.name === editItem.item,
      );

      editItem.client = this.$store.state.clientList.find(
        (x) => x.name === editItem.client,
      );
      editItem.order = this.$store.state.orderList.find(
        (x) => x.order === editItem.order,
      );

      editItem.color = item.name;
      editItem.kind = 3;
      // editItem.kind = editItem.kind
      //   ? this.stockKind.find((x) => x.title === editItem.kind)
      //   : "";
      this.$store.commit("setEditStockForm", editItem);
    },

    deleteStock(item) {
      if (confirm(`${item.name ? item.name : ""}를 삭제하시겠습니까?`)) {
        this.$store.dispatch("TASK_SAVE_STOCK", { data: item, type: "delete" });
      }
    },
    clearRow() {
      this.onRow = "";
    },
    menuOpen(event, item) {
      this.$store.dispatch("CONTEXT_MENU_DATA_PARSE", {
        item: item,
      });
      this.$refs.menu.$children[0].open(event);
    },
    orderChange(event) {
      if (event) {
        this.editStockForm.item = this.$store.state.itemList.find(
          (x) => x._id === event.item,
        );
        this.editStockForm.client = this.$store.state.clientList.find(
          (x) => x._id === event.client,
        );
      }
    },
    kindChange(data) {
      this.editStockForm.kind = data;
    },

    openEditDialog(item, target, type) {
      this.$store.commit("setEditedItemForm");
      this.editForm = { ...item };
      this.editForm.type = type;

      this.editForm.target = target;
      this.editForm.business_num
        ? (this.editForm.business_num = this.getBusinessNumMask(
            this.editForm.business_num,
          ))
        : "";

      this.editForm.tel
        ? (this.editForm.tel = this.getPhoneMask(this.editForm.tel))
        : "";
      this.editForm.unit_price = this.editedItem.unit_price
        ? this.usingComma(this.editedItem.unit_price)
        : "";
      this.$store.commit("setEditedItemForm", this.editForm);
      this.$store.commit("setDailogStatus", {
        status: true,
        kind: "setting_form",
      });
    },
  },
  async created() {
    if (this.pageParams.length && this.pageParams[0].field === "pageMove")
      this.pageMove();
    else {
      this.search();
    }
  },
  mounted() {
    this.keydownListener = (e) => {
      if (e.keyCode == 27 && this.isFilter) {
        this.initFilter();
      }
    };
    document.addEventListener("keydown", this.keydownListener);

    if (this.$store.state.stockView) this.checkScroll();
  },
  // sockets: {
  //   async stock(data) {
  //     if (this.$store.state.auth_company === data.company)
  //       await this.$store.dispatch("GET_STOCK_TABLE_LIST", {
  //         stockType: "stock",
  //         sortHeader: this.$store.state.applySortList["stock"],
  //       });
  //   },
  // },

  beforeDestroy() {
    this.observer.unobserve(this.$refs.endPoint);
    this.observer.disconnect();
    document.removeEventListener("keydown", this.keydownListener);
    this.keydownEventListener = null;
  },
};
